<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">코스 등록</h4>
          <p class="card-title-desc">신규 코스를 등록합니다.</p>
          <form action="#" @submit.prevent="typeForm">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label>코스이름</label>
                  <input
                    v-model="typeform.name"
                    type="text"
                    class="form-control"
                    placeholder="코스 이름을 입력하세요."
                    name="name"
                    autocomplete="off"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.name.$error
                    }"
                  />
                  <div v-if="typesubmit && $v.typeform.name.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.name.required">값을 입력해주세요.</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>공개여부</label>
                  <select v-model="typeform.openYn" class="form-control">
                    <option value="Y" selected>공개</option>
                    <option value="N">비공개</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>정렬순서</label>
                  <input
                    v-model="typeform.orderNum"
                    type="text"
                    class="form-control"
                    placeholder="정렬순서를 입력하세요."
                    name="name"
                    autocomplete="off"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.orderNum.$error
                    }"
                  />
                  <div v-if="typesubmit && $v.typeform.orderNum.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.orderNum.required">값을 입력해주세요.</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>소개영상</label>
                  <input
                    v-model="typeform.movieUrl"
                    type="text"
                    class="form-control"
                    placeholder="정렬순서를 입력하세요."
                    name="movieUrl"
                    autocomplete="off"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.movieUrl.$error
                    }"
                  />
                  <div v-if="typesubmit && $v.typeform.movieUrl.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.movieUrl.required">값을 입력해주세요.</span>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>가격(1년)</label>
                  <div>
                    <input
                      v-model="typeform.price"
                      type="text"
                      name="price"
                      autocomplete="off"
                      class="form-control"
                      :class="{
                        'is-invalid': typesubmit && $v.typeform.price.$error
                      }"
                      placeholder="가격을 입력하세요(무료는 0)"
                    />
                    <div v-if="typesubmit && $v.typeform.price.$error" class="invalid-feedback">
                      <span v-if="!$v.typeform.price.required">
                        값을 입력해주세요.
                      </span>
                    </div>
                    <div v-if="typesubmit && $v.typeform.price.$error" class="invalid-feedback">
                      <span v-if="!$v.typeform.price.numeric">
                        숫자만 입력할 수 있습니다.
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label>썸네일</label>
                  <input
                    type="file"
                    class="form-control"
                    ref="file1"
                    autocomplete="off"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    accept="image/*"
                    @change="fileSelect"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>레벨</label>
                  <div>
                    <input
                      v-model="typeform.level"
                      type="text"
                      name="price"
                      autocomplete="off"
                      class="form-control"
                      :class="{
                        'is-invalid': typesubmit && $v.typeform.level.$error
                      }"
                      placeholder="레벨을 입력하세요"
                    />
                    <div v-if="typesubmit && $v.typeform.level.$error" class="invalid-feedback">
                      <span v-if="!$v.typeform.level.required">
                        값을 입력해주세요.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="form-group">
                  <label>강의시간</label>
                  <input
                    v-model="typeform.courseTime"
                    type="text"
                    name="price"
                    autocomplete="off"
                    class="form-control"
                    placeholder="코스 시간을 입력하세요"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>그룹</label>
                  <select v-model="typeform.courseGroup" class="form-control">
                    <option v-for="item in courseGroupData" :key="item.idx" :value="item.idx">{{
                      item.name
                    }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>한줄설명</label>
                  <textarea
                    v-model="typeform.shortIntroduce"
                    name="textarea"
                    autocomplete="off"
                    class="form-control"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.shortIntroduce.$error
                    }"
                  ></textarea>
                  <div
                    v-if="typesubmit && $v.typeform.shortIntroduce.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.typeform.shortIntroduce.required">
                      값을 입력해주세요.
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>짧은설명</label>
                  <textarea
                    v-model="typeform.middleIntroduce"
                    name="textarea"
                    autocomplete="off"
                    class="form-control"
                    :class="{
                      'is-invalid': typesubmit && $v.typeform.middleIntroduce.$error
                    }"
                  ></textarea>
                  <div
                    v-if="typesubmit && $v.typeform.middleIntroduce.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.typeform.middleIntroduce.required">
                      값을 입력해주세요.
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>자주 묻는 질문</label>
                  <textarea
                    v-model="typeform.qna"
                    name="textarea"
                    autocomplete="off"
                    class="form-control qnaText"
                  ></textarea>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group editors">
                  <label>설명</label>
                  <div v-if="typesubmit && $v.typeform.introDuce.$error" class="invalid-feedback">
                    <span v-if="!$v.typeform.introDuce.required">
                      값을 입력해주세요.
                    </span>
                  </div>
                  <CkeditorNuxtBasic v-model="typeform.introDuce" :config="config" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-0">
                  <div>
                    <button type="submit" class="btn btn-primary me-1">
                      {{ typeform.id !== '' ? '수정' : '등록' }}
                    </button>
                    <button
                      type="reset"
                      class="btn btn-secondary m-l-5 ml-1"
                      @click="$router.push('/course')"
                    >
                      취소
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {validationMixin} from 'vuelidate';
import {required, numeric} from 'vuelidate/lib/validators';
import CkeditorNuxtBasic from '@/components/editor/CkeditorNuxtBasic.vue';
export default Vue.extend({
  components: {
    CkeditorNuxtBasic
  },
  mixins: [validationMixin],
  props: {
    pageData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      typeform: {
        id: '',
        name: '', // 강의명
        openYn: '', // 공개여부
        orderNum: '', // 정렬번호
        movieUrl: '', // 동영상 url
        file1: null, // 파일이름
        level: '', // 난이도 초급 중급 고급
        courseGroup: '', // 코스 그룹
        courseTime: '',
        shortIntroduce: '', // 한줄소개
        middleIntroduce: '', // 짧은소개
        introDuce: '', // 상세 소개
        price: '', // 12달 가격
        qna: '' // 자주 묻는 질문
      },
      config: {
        image: {
          resizeUnit: '%',
          resizeOptions: [
            {
              name: 'resizeImage:original',
              value: null
            },
            {
              name: 'resizeImage:25',
              value: '25'
            },
            {
              name: 'resizeImage:50',
              value: '50'
            },
            {
              name: 'resizeImage:75',
              value: '75'
            }
          ],
          toolbar: [
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
            '|',
            'toggleImageCaption',
            'imageTextAlternative',
            'resizeImage'
          ]
        },
        codeBlock: {
          languages: [
            {language: 'css', label: 'CSS'},
            {language: 'html', label: 'HTML'},
            {language: 'javascript', label: 'JavaScript'},
            {language: 'python', label: 'Python'}
          ]
        },
        alignment: {
          options: ['left', 'right', 'center']
        },
        htmlSupport: {
          allow: [
            /* HTML features to allow */
            {
              name: /.*/,
              attributes: true,
              classes: true,
              styles: true
            }
          ],
          disallow: [
            /* HTML features to disallow */
            {
              name: 'iframe'
            }
          ]
        }
      },
      courseGroupData: [],
      submit: false,
      typesubmit: false
    };
  },
  // watch: {
  //   typeform: {
  //     handler(val) {
  //       console.log(val.introDuce);
  //     },
  //     deep: true
  //   }
  // },
  mounted() {
    console.log(this.pageData);
    if (Object.keys(this.pageData).length > 0) {
      this.typeform.id = this.pageData.idx;
      this.typeform.name = this.pageData.name;
      this.typeform.openYn = this.pageData.openYn;
      this.typeform.orderNum = this.pageData.orderNum;
      this.typeform.movieUrl = this.pageData.movieUrl;
      this.typeform.level = this.pageData.level;
      this.typeform.courseTime = this.pageData.courseTime;
      this.typeform.shortIntroduce = this.pageData.shortIntroduce;
      this.typeform.middleIntroduce = this.pageData.middleIntroduce;
      this.typeform.courseGroup = this.pageData.courseGroup;
      this.typeform.qna = this.pageData.courseQna;
      setTimeout(() => {
        this.typeform.introDuce = this.pageData.introduce;
      }, 100);
      this.typeform.price = this.pageData.price;
    }

    this.$store.dispatch('course/course_group_list', {}).then(res => {
      this.courseGroupData = res.list;
      if (Object.keys(this.pageData).length === 0) {
        this.typeform.courseGroup = res.list[0].idx;
      }
    });
  },
  validations: {
    typeform: {
      name: {required},
      openYn: {required},
      orderNum: {required, numeric},
      movieUrl: {required},
      level: {required},
      shortIntroduce: {required},
      middleIntroduce: {required},
      introDuce: {required},
      price: {required, numeric}
    }
  },
  methods: {
    fileSelect() {
      this.typeform.file1 = this.$refs.file1.files[0];
      console.log(this.typeform.file1);
    },
    async typeForm() {
      this.typesubmit = true;
      this.$v.typeform.$touch();
      if (!this.$v.$invalid) {
        await this.$store
          .dispatch('course/addCourse', {
            thumnailImage: this.typeform.file1,
            name: this.typeform.name,
            openYn: this.typeform.openYn,
            orderNum: this.typeform.orderNum,
            movieUrl: this.typeform.movieUrl,
            level: this.typeform.level,
            courseTime: this.typeform.courseTime,
            courseGroup: this.typeform.courseGroup,
            shortIntroduce: this.typeform.shortIntroduce,
            middleIntroduce: this.typeform.middleIntroduce,
            introDuce: this.typeform.introDuce,
            price: this.typeform.price,
            id: this.typeform.id,
            qna: this.typeform.qna
          })
          .then(res => {
            console.log(res);
            if (this.typeform.id !== '') {
              alert('코스가 수정되었습니다.');
            } else {
              alert('코스가 등록되었습니다.');
              this.$router.push('/course');
            }
          });
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.editors::v-deep {
  li {
    list-style-type: none;
  }

  h2 {
    font-size: 18px;
    font-weight: normal;
    margin-top: 2rem;
    margin-bottom: 2rem;
    line-height: 1.7;
  }

  h3 {
    font-size: 17px;
    font-weight: normal;
    margin-top: 16px;
    margin-bottom: 16px;
    color: #444;
  }

  h4 {
    font-size: 16px;
    font-weight: normal;
    margin-top: 16px;
    margin-bottom: 16px;
    color: #444;
  }

  .first-ol {
    ol {
      display: flex;
      justify-content: space-around;
      text-align: center;

      li:nth-child(1)::before {
        content: '👶';
        display: block;
        font-size: 30px;
      }

      li:nth-child(2)::before {
        content: '👦';
        display: block;
        font-size: 30px;
      }

      li:nth-child(3)::before {
        content: '🧔';
        display: block;
        font-size: 30px;
      }
    }
  }

  .check-ol + ol {
    li {
      line-height: 1.7;
    }

    li::before {
      content: '✔';
      color: green;
      display: inline-block;
      margin-right: 10px;
    }
  }

  ul li {
    list-style: disc;
    list-style-position: inside;
    line-height: 2;
  }

  .image_resized {
    width: 70% !important;
    margin-left: auto;
    margin-right: auto;
  }

  .text-center {
    text-align: center;
  }

  hr {
    width: 25%;
    margin: 3rem auto;
  }

  .qna-box {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 1rem;
    margin: 1rem 0;
    p:last-child {
      margin-bottom: 0;
    }
  }
}
.qnaText {
  height: 150px;
}
</style>
